import React from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import { useHistory, Redirect } from "react-router-dom";
// import timeGridPlugin from "@fullcalendar/timegrid";

//@ts-ignore
export default function Calendar({ myevents }: any) {
    const history = useHistory();
    return (
        <div>
            <FullCalendar
                //@ts-ignore
                defaultView="dayGridMonth"
                header={{
                    left: "prev,next",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                themeSystem="Simplex"
                plugins={[dayGridPlugin]}
                //@ts-ignore
                events={myevents}
                eventClick={
                    function (info) {
                        history.push("/panel/" + info.event._def.extendedProps.ide);
                    }
                }
            />
        </div>
    )
}
