import RadioButton from "../Components/RadioButton";
import DateTime from "../Components/DateTime";
import { useFormikContext } from 'formik';

export default function MeetingsForm() {
    const { setFieldValue }: any = useFormikContext();
    return (
        <>
            <div className="section__content">
                <h4 className="section__title">
                    Día y Hora de Encuentro / Meeting Schedule
                </h4>
                <div className="cols">
                    <div className="col-md-12">
                        <div className="form__element">
                            <div className="form__title">
                                <DateTime
                                    setFieldValue={setFieldValue}
                                    type="text"
                                    name="meeting_datetime"
                                    label="Fecha y Hora del Encuentro / Meeting Date" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section__content">
                <h4 className="section__title">
                    Lugar de Encuentro / Meeting Place
                </h4>
                <div className="cols">
                    <div className="col-md-12">
                        <div className="form__element">
                            <div className="form__title">
                            </div>
                
                                <RadioButton
                                    name="meeting_place"
                                    value="Sala Embarque"
                                    label="Sala Embarque / Boarding room"
                                />
                                <RadioButton
                                    name="meeting_place"
                                    value="Mesón Información"
                                    label="Mesón Información / Information Desk"
                                />
            
                            <RadioButton
                                    name="meeting_place"
                                    value="Sector Estacionamiento"
                                    label="Sector Estacionamiento / Parking Area"
                                />
                                    <RadioButton
                                        name="meeting_place"
                                        value="Acceso al Terminal de Pasajeros"
                                        label="Acceso al Terminal de Pasajeros / Access to the Passenger Terminal"
                                    />
        
                        </div>
                    </div>
                </div>
            </div>

            <div className="section__content">
                <h4 className="section__title">
                  Embarque o  Desembarque / Arrival or Departure
                </h4>
                <div className="cols">
                    <div className="col-md-12">
                        <div className="form__element">
                            <div className="form__title">
                            </div>
                            <div className="form__radios">
                                <RadioButton
                                    name="requirement_type"
                                    value="embark"
                                    label="Embarque / Departure"
                                />
                                <RadioButton
                                    name="requirement_type"
                                    value="disembark"
                                    label="Desembarque / Arrival"
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
