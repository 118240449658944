import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Chip from "@mui/material/Chip";

interface StateTickets {
  canceled: number;
  closed: number;
  completed: number;
  delayed: number;
  in_process: number;
  pending: number;
  reopen: number;
}

export default function StateTickets() {
  const [stateTickets, setStateTickets] = useState<StateTickets>();

  useEffect(() => {
    axios
      .get("/assistances/stats")
      .then((response) => {
        
        setStateTickets(response.data.assistances);
      })
      .catch((error) => {
        console.log('error',error)
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: 26,
      }}
    >
      <Chip
        label={"Por Procesar: " + (stateTickets?.pending || 0)}
        color="primary"
        variant="outlined"
      />
 
      <Chip
        label={"Completados: " + (stateTickets?.completed || 0)}
        color="primary"
        variant="outlined"
      />

      <Chip
        label={"Anulados: " + (stateTickets?.canceled || 0 )}
        color="primary"
        variant="outlined"
      />
    </div>
  );
}
