import TxtInput from "../Components/TxtInput";
import RadioButton from "../Components/RadioButton";
import CheckBox from "../Components/CheckBox";
import { useFormikContext } from 'formik';
import PhoneNumber from "../Components/PhoneNumber";

export default function AssistanceForm({ label, ...props }: any) {
    const { values, setFieldValue }: any = useFormikContext();
    const setPhoneNumber = (name: any, value: any) => {
        setFieldValue(name, value)
    }
    return (
        <>
            <h4 className="section__title">
                Información del Solicitante/ Applicant Information
            </h4>
            <div className="cols">
                <div className="col-md-6">
                    <TxtInput
                        type="text"
                        name="name"
                        label="Nombre / Name"
                    />
                </div>
                <div className="col-md-6">
                    <TxtInput
                        type="text"
                        name="lastname"
                        label="Apellido / LastName"
                    />
                </div>
                <div className="col-md-6" style={{ marginBottom: 0 }}>
                    <div className="form__element">
                        <div className="form__title">
                            Rut o Pasaporte /  or Passport
                        </div>
                        <div className="form__radios">
                            <RadioButton
                                name="DNI"
                                value="Rut"
                                label="Rut"
                            />
                            <RadioButton
                                name="DNI"
                                value="passport"
                                label="Pasaporte / Passport"
                            />
                        </div>
                    </div>
                </div>
                {values?.DNI == "Rut" && (
                    <div className="col-md-6">
                        <TxtInput
                            type="text"
                            name="rut"
                            label="Rut / N° ID"
                        />
                    </div>
                )}
                {values?.DNI == "passport" && (
                    <div className="col-md-6">
                        <TxtInput
                            type="text"
                            name="passport"
                            label="Pasaporte / passport"
                        />
                    </div>
                )}
                <div className="col-md-6">
                    <PhoneNumber
                        name="phone"
                        label="Teléfono / Phone"
                        setPhoneNumber={setPhoneNumber}
                    />
                </div>
                <div className="col-md-6">
                    <TxtInput
                        type="text"
                        name="email"
                        label="Correo Electrónico / Email"
                    />
                </div>
                <div className="col-md-6">
                    <CheckBox
                        name="same_person"
                        label="Soy el pasajero/ I'm the passenger " />
                </div>
            </div>
        </>
    )
}
