import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

type Props = {
  txt: string,
  startDate: any, 
  setStartDate: any,
  disabled?: boolean
}
export default function TimePickerForm({txt, startDate, setStartDate, disabled}: Props ) {
  return (
    <div 
    style={{ marginLeft:10}}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <TimePicker
          disabled={disabled}
          label={txt}
          value={startDate}
          onChange={(newValue:any) => {
            setStartDate(newValue);
        }}
          renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
    </div>
  );
}