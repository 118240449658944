import { useState } from "react";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Note() {

    const [lenguaje, setLenguaje] = useState(false);
    return (
        <div>
            <div className="form">
                <div className="center-alert">
                    <FormControlLabel
                        control={
                            <Switch color="error" checked={lenguaje} onChange={() => { setLenguaje(prev => !prev) }} name="jason" />
                        }
                        label="English"
                    />
                    {!lenguaje ?
                        <>
                            <h4>Importante:</h4>
                            <p>EMBARQUE: Si solicitaste el servicio con 48 hr. de antelación, y si llegas puntual, un asistente PMR te estará esperando en el punto de
                                encuentro o llegará en máximo 5 minutos desde la hora solicitada.
                            </p>
                            <p>
                                DESEMBARQUE: Tu tiempo de espera será de hasta 10 minutos (si lo solicitaste con más de 48 horas de anticipación).
                            </p>
                            <p>
                                NOTA. Si para el embarque llegaste con atraso de más de 5 minutos, o si para el desembarque llegaste con atraso de más de 15 minutos,
                                entonces dirígete a Mesones de Informaciones.
                            </p>
                        </> :
                        <>
                            <h4>Important:</h4>
                            <p>Arrival: If you requested the service 48 hr. in advance, and if you arrive on time, a PMR assistant will be waiting for you at the meeting point or will arrive in a maximum of 5 minutes from the requested time.
                            </p>
                            <p>
                            Departure: Your waiting time will be up to 10 minutes (if you requested it more than 48 hours in advance).
                            </p>
                            <p>
                            NOTE. If you arrived more than 5 minutes late for boarding or more than 15 minutes late for departure, go to Information Desks.
                            </p>
                        </>}
                </div>
            </div>

        </div>
    )
}
