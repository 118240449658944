import axios from '../utils/axios';
import { parseDate } from './helpers';
export function loadServerRows(target: string, page: number = 0, limit: number = 5, filter = {}): Promise<any> {

  return new Promise((resolve) => {
    const param = { page, limit, ...filter }
    axios.get(target, { params: param }).then((res: any) => {
      if (target == 'assistances') {
        res.data.assistances = res.data.assistances.map((item: {
          status: string;
          with_alert: any; meeting_datetime: string | number | Date;
        }) => {
          let date = parseDate(item.meeting_datetime.toString())
          return {
            ...item,
            with_alert: item.with_alert ? 'Sí' : 'No',
            meeting_datetime: date,
            status: item.status == 'Cancelado'? 'Anulado' : item.status
          }
        })
      }
      resolve({
        rows: res.data[target],
        totalCount: res.data.total_pages,
      });
    });
  });
}