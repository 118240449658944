import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { useField } from "formik";
import esLocale from 'date-fns/locale/es';

export default function DateTime({ label, ...props }: any) {
    const [field, meta] = useField(props);

    return (
    <div 
    style={{ marginLeft:10}}
    className="dateTime form__element">
              <div className="form__title">{label}      {meta.touched && meta.error ? (
          <label className="error">{meta.error}</label>
        ) : null} </div>
    <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={esLocale}>
    <MobileDateTimePicker
        label={label}
         {...field} {...props}
         onChange={(value: any) => {
            props.setFieldValue('meeting_datetime', value);
            }}
          renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
    </div>
  );
}