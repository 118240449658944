import { useField } from 'formik'

const Checkbox = ({ children, label,...props }:any) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <div>
      <label aria-label={label}>
        <input id="checkbox" type="checkbox" {...field} {...props} />
        <>{children} {label}</> 
      </label>
      {meta.touched && meta.error ?
        <div>{meta.error}</div> : null}
    </div>
  )
}

export default Checkbox
