import { useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import iReport from '../../../interfaces/report.interface';
import { ConstructionOutlined } from '@mui/icons-material';
import { forEachChild } from 'typescript';


export const options = {
  title: "Embarques / Desembarques",
  chartArea: { width: "50%" },
  hAxis: {
    title: "Cantidad",
    minValue: 0,
  },
  vAxis: {
    title: "Meses",
  },
};


export default function GraphCrs({report,title}:{report:any, title:string[]}) {

  const [formatManager, setFormatManager] = useState<any>(null);

  useEffect(() => {
    /*
      {
        "assistances": {
          "[\"06-2022\", 0]": 8,
          "[\"06-2022\", 1]": 1,
          "[\"07-2022\", 0]": 2,
          "[\"07-2022\", 1]": 1
        }
      }
     */
      const getMonths = (month: string)=>{
        const months:any = {
          '01':'Enero',
          '02':'Febrero',
          '03':'Marzo',
          '04':'Abril',
          '05':'Mayo',
          '06':'Junio',
          '07':'Julio',
          '08':'Agosto',
          '09':'Septiembre',
          '10':'Octubre',
          '11':'Noviembre',
          '12':'Diciembre'
        }
        return months[month];
      }

      const data = [
        ["Mes", "Embarque", "Desembarque"],
      ]

      const  mapped2 = Object.entries(report.assistances).sort()
      const monthYear:{ [id: string] : any} = {}

      for (let i = 0; i < mapped2.length; i++){
        let nmonth =  mapped2[i][0].slice(2,4);
        nmonth = nmonth.split(" ")[0]
        let anio = mapped2[i][0].slice(5,9);
        let locMont = getMonths(nmonth)
        let total:any =mapped2[i][1]
        if (!Object.keys(monthYear).includes(locMont+'-'+anio)) {
          if (mapped2[i][0][12] === '0') {
            // 0 equals to disembark
            monthYear[locMont+'-'+anio] = {
              disembark: Number(total),
              embark: 0
            }
          } else {
            monthYear[locMont+'-'+anio] = {
              embark : Number(total),
              disembark: 0
            }
          }
        } else {
          if (mapped2[i][0][12] === '0') {
            // 0 equals to disembark
            monthYear[locMont+'-'+anio]['disembark'] = Number(total)
          } else {
            monthYear[locMont+'-'+anio]['embark'] = Number(total)
          }
        }
      }
      Object.keys(monthYear).forEach((key)=>{
        let embark = monthYear[key]['embark']
        let disembark = monthYear[key]['disembark']
        data.push(
          [key, embark, disembark ]
        )
      })
    /*
      export const data = [
        ["Mes", "Embarque", "Desembarque"],
        ["Enero", 8175000, 8008000],
        ["Febrero, CA", 3792000, 3694000],
        ["Marzo", 2695000, 2896000],
        ["Abril", 2099000, 1953000],
        ["Mayo", 1526000, 1517000],
      ];
    */
    //@ts-ignore
    setFormatManager(data)

  },[report]);


    return (<>
      {formatManager && 
              <Paper>
                <Chart
                  chartType="BarChart"
                  width="75%"
                  height="400px"
                  data={formatManager}
                  options={options}
                />
          </Paper>
      }
      </>
  )
}