import * as Yup from "yup";
import { rutValidate } from "rut-helpers";

const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Campo requerido").email("Email inválido"),
    phone: Yup.string().required("Campo requerido"),
    description: Yup.string(),
    lastname: Yup.string().required("Campo requerido"),
    name: Yup.string().required("Campo requerido"),
    requirement_type: Yup.string().required("Campo requerido"),
    rut: Yup.string().test("required", function (value: any) {

      if (this.parent.DNI == "passport") {
        return true;
      }
      return rutValidate(value)
        ? true
        : this.createError({
          message: `Rut inválido`,
          path: 'rut', // Fieldname
        });
  
    }),
    passport: Yup.string().test("required", function (value: any) {
      if (this.parent.DNI == "passport") {
        return value;
      } else {
        return true;
      }
    }),
    passenger_passport: Yup.string().when('same_person', {
      is: false,
      then: Yup.string().test("required", function (value: any) {

        if (this.parent.passenger_DNI == "passport") {
          return value;
        } else {
          return true;
        }
      }),
    }),
    passenger_rut: Yup.string().when('same_person', {
      is: false,
      then: Yup.string().test("required", function (value: any) {

        if (this.parent.passenger_DNI == "passport") {
          return true;
        }
        return rutValidate(value)
          ? true
          : this.createError({
            message: `Rut inválido`,
            path: 'passenger_rut', // Fieldname
          });
  
      }),
    }),
    passenger_email: Yup.string().when('same_person', {
      is: false,
      then: Yup.string().email("Email inválido").required("Campo requerido")
    }),
    passenger_name: Yup.string().when('same_person', {
      is: false,
      then: Yup.string().required("Campo requerido")
    }),
    passenger_lastname: Yup.string().when('same_person', {
      is: false,
      then: Yup.string().required("Campo requerido")
    }),
    passenger_phone_number: Yup.number().when('same_person', {
      is: false,
      then: Yup.number()
    }),
    meeting_place: Yup.string().required("Campo requerido"),
    meeting_datetime: Yup.string().required("Campo requerido"),
 });

export default SignupSchema;