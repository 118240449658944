import { useState } from "react";
import { Formik, Form } from "formik";
import PmrSchema from './PmrSchema'
import TextArea from "../Components/TextArea";
import ThanksPostForm from "./ThanksPostForm";
import { createFormData, api } from './utils'
import AssistanceForm from "./AssistanceForm";
import PassengerForm from "./PassengerForm";
import MeetingsForm from "./MeetingsForm";
import Note from "./Note";


export default function CrsForm() {
  const [sendData, setSendData] = useState(false);

  const onSubmit = async (values: any, resetForm: any) => {

    const formData = createFormData(values)

    return fetch(`${api}/assistances`, {
      body: JSON.stringify(formData),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => {
        if (data.status === 200) {
          resetForm();
          setSendData(true);

          setTimeout(() => {
            window.location.href = "https://www.aeropuertodepuertomontt.cl/"
            //setSendData(false);
          }, 5000);
        } else {
          alert('Error en el servidor, por favor contactar a un administrador')
        }

        return 'ok'
      })
      .catch((error) => {

        console.log(error);
        alert('Error en el servidor, por favor contactar a un administrador')
      });
  };

  return (
    <>
      {!sendData && (
        <div id="sections">
          <section className="section--form">
            <div className="section__bg">
              <div className="bg bg-loaded"> </div>
            </div>
            <div className="section__logo">
              {" "}
              <img src="../img/icons/logo-color.png" />
            </div>
            <div style={{
                  position: 'relative',
                  marginTop: 110,
                  marginBottom: -48,
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'white'
              }}>
            <h3>{`Asistencia para Pasajeros con Movilidad Reducida (PMR)`}</h3>
            </div>
            <div className="section__wrap">
              <div className="section__form">
                <Formik
                  initialValues={{
                    email: "",
                    DNI: "Rut",
                    rut: "",
                    name: "",
                    lastname: "",
                    phone: "",
                    passport: "",
                    passenger_passport: "",
                    passenger_email: "",
                    passenger_DNI: "Rut",
                    passenger_rut: "",
                    passenger_name: "",
                    passenger_lastname: "",
                    passenger_phone_number: "",
                    meeting_place: "",
                    meeting_datetime: null,
                    description: "",
                    same_person: false,
                    requirement_type: ''
                  }}
                  validationSchema={PmrSchema}
                  onSubmit={async (values, { resetForm }) =>
                    await onSubmit(values, resetForm)
                  }
                >
                  {(props) => (
                    <Form className="form">
                      <div className="section__content">
                        <AssistanceForm />
                      </div>

                      {!props.values.same_person &&
                        <PassengerForm />
                      }

                      <MeetingsForm />

                      <div className="section__content">
                        <h4 className="section__title">
                          Descripción de la solicitud/ Description
                        </h4>
                        <div className="cols">
                          <div className="col-md-12">
                            <TextArea
                              type="text"
                              name="description"
                              label="Descripción/ Description"
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="form__buttons">
                              <button disabled={props.isSubmitting} type="submit" className="btn btn--small">
                                {props.isSubmitting ? 'Enviando..' : 'Enviar solicitud'}
                              </button>
                              <a
                                href="index.html"
                                className="btn btn--small btn--gray"
                              >
                                Cancelar
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <Note/>
              </div>
            </div>
          </section>
        </div>
      )}
      {sendData && <ThanksPostForm />}
    </>
  );
}