import React, { useRef } from 'react';
import PMRReport from "./TotalReport/PMRReport";
import { useReactToPrint } from 'react-to-print';

export default function Report() {

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div ref={componentRef} >
          <PMRReport />
      </div>
      <br></br><br></br>
      <button onClick={()=>{  window.print()}}  className="btn btn--small noprint">Pasar a PDF esta ventana</button>
    </>
  );
}
