import React from "react";
import {
  DataGrid,
  GridRowsProp,
  GridSelectionModel,
  GridFilterModel,
} from "@mui/x-data-grid";

import { loadServerRows } from "../utils/loadServerRows";

export default function Table({ columns, targetApi, reload, modeOperand }: any) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [querySorting, setQuerySorting] = React.useState<object>();
  const [rowCount, setrowCount] = React.useState(100);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [loading, setLoading] = React.useState(false);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const prevSelectionModel = React.useRef<GridSelectionModel>(selectionModel);
  const [filterValue, setFilterValue] = React.useState<string | undefined>();
  const [filterColumnValue, setFilterColumnValue] = React.useState<
    string | undefined
  >();

  const filterColumnParse = (column: string = "") => {
    switch (column) {
      case "status":
        return "query[status_in]";
      case "assistance_number":
        return "query[assistance_number_cont]";
      case "applicant_full_name":
        return "query[applicant_full_name]";
      default:
        return "";
    }
  };

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setFilterValue(filterModel.items[0].value);
    setFilterColumnValue(filterModel.items[0].columnField);
  }, []);

  const handleSortModelChange = React.useCallback((sortModel:any) => {
    const queryFilter = { [  `sort[${sortModel[0].field}]`  ]: sortModel[0].sort };
    setQuerySorting(queryFilter)
}, []);

const getQueryByFilter = () =>{
  const filterColumn: string = filterColumnParse(filterColumnValue);
  if (filterColumn === "") return false;
  return { [filterColumn]: filterValue };
}

const createQueryParams = ()=>{
  let data = {}
  const resultFilter = getQueryByFilter();
  if(resultFilter){
    data = {...resultFilter }
  }
  if(querySorting){
    data = {...data, ...querySorting }
  }
  return data
}

const getParamsData = async(queryFilter:object)=>{
  setLoading(true);
  const newRows = await loadServerRows(targetApi, page, limit, queryFilter);
  setRows(newRows.rows);
  setLoading(false);
  setTimeout(() => {
    setSelectionModel(prevSelectionModel.current);
  });
}

  React.useEffect(() => {
    if(filterValue || querySorting){
      const data = createQueryParams();
      if(data){
        getParamsData(data)
      }
    }
  }, [filterValue, querySorting]);

  //sort
  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const data = createQueryParams()
      const newRows = await loadServerRows(targetApi, page, limit, data);
      if (!active) {
        return;
      }
      setrowCount(newRows.totalCount * newRows.rows.length);
      setRows(newRows.rows);
      setLoading(false);
      setTimeout(() => {
        setSelectionModel(prevSelectionModel.current);
      });
    })();

    return () => {
      active = false;
    };
  }, [page, reload]);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        sortingMode="server"
        pagination
        filterMode={modeOperand}
        onFilterModelChange={onFilterChange}
        onSortModelChange={handleSortModelChange}
        pageSize={limit}
        rowsPerPageOptions={[limit]}
        rowCount={rowCount}
        paginationMode={modeOperand}
        onPageChange={(newPage) => {
          prevSelectionModel.current = selectionModel;
          setPage(newPage + 1);
        }}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        loading={loading}
      />
    </div>
  );
}
