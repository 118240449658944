import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Formik, Form } from 'formik';
import TxtInputMaterial from '../../Components/TxtInputMaterial';
import TimePickerForm from '../../Components/TimePickerForm';

import { useParams } from 'react-router-dom';
//import axios
import iTickets from '../../interfaces/ticket.interface';
import axios from '../../utils/axios';
import Checkbox from '../../Components/CheckBox';
import { parseDate } from '../../utils/helpers';
import { Button } from '@mui/material';

export default function UpdateTIcket() {
  const { id }: any = useParams();
  const [ticket, setTicket] = useState<iTickets>();
  const [startDate, setStartDate] = useState<Date | null>(null);

  const now = new Date(ticket?.meeting_datetime || '');
  const twoHoursAgo = new Date(now?.getTime() - 2 * 60 * 60 * 1000);
  const oneHoursAfter = new Date(now.getTime() + 1 * 60 * 60 * 1000);
  const [attentionEnabled, setAttentionEnabled] = useState(
    isCurrentTimeInRange(twoHoursAgo, oneHoursAfter)
  );

  function isCurrentTimeInRange(startTime: Date, endTime: Date) {
    const currentTime = new Date().getTime();
    const start = new Date(startTime).getTime();
    const end = new Date(endTime).getTime();

    if (currentTime >= start && currentTime <= end) {
      return true;
    } else {
      return false;
    }
  }

  //useEffect get id ticket
  useEffect(() => {
    //get axios ticket
    axios.get(`/assistances/${id}`).then((res: any) => {
      const response: iTickets = res.data;
      if (response.attention_start_time) {
        setStartDate(response.attention_start_time);
      }
      setTicket(response);
    });
  }, [id]);

  useEffect(() => {
    if (startDate && ticket?.meeting_datetime) {
      const RulesBussinesAboutSanction: any = {
        'true-Desembarque': 10 * 60000,
        'false-Desembarque': 25 * 60000,
        'true-Embarque': 5 * 60000,
        'false-Embarque': 25 * 60000,
      };

      const timeTolerance =
        RulesBussinesAboutSanction[
          ticket?.with_alert.toString() + '-' + ticket.requirement_type
        ];

      let meeting_datetime = new Date(ticket?.meeting_datetime);

      let date = new Date(ticket?.meeting_datetime);
      date.setHours(startDate.getHours(), startDate.getMinutes(), 0, 0);

      var difference = date.getTime() - meeting_datetime.getTime();

      if (timeTolerance < difference) {
        alert('EL horario puesto excede el rango esperado, por favor revisar');
      }
    }
  }, [startDate]);

  const onSubmit = (values: any) => {
    let formData = new FormData();

    if (values.canceled) {
      formData.append('assistance[status]', 'canceled');
      axios
        .put(`/assistances/${id}`, formData)
        .then((res) => {
          alert('se ingresaron los datos');
        })
        .catch((err) => {
          alert('ocurrio un error');
        });
      return;
    }

    // if(startDate && ticket?.meeting_datetime){
    //   const meet = new Date(ticket?.meeting_datetime)
    //   const today = new Date();
    //   console.log(meet, "meet")
    //   console.log(today, "today")
    //   if(meet.toISOString().split('T')[0] > today.toISOString().split('T')[0]){
    //     alert('Debe esperar al día y hora programado para registrar el horario')
    //     return
    //   }
    // }

    if (startDate && values.responsible_scas && ticket?.meeting_datetime) {
      let date = new Date(ticket?.meeting_datetime);

      let startDateparse = new Date(startDate);
      date.setHours(
        startDateparse.getHours(),
        startDateparse.getMinutes(),
        0,
        0
      );

      formData.append('assistance[attention_start_time]', date.toString());

      formData.append('assistance[status]', 'completed');

      formData.append('assistance[responsible_scas]', values.responsible_scas);

      //En proceso'

      axios
        .put(`/assistances/${id}`, formData)
        .then((res) => {
          alert('se ingresaron los datos');
        })
        .catch((err) => {
          alert('ocurrio un error');
        });
    } else {
      alert('ingrese fecha de incio y responsable');
    }
  };

  return (
    <div>
      <h3>Detalle de Solicitud</h3>
      {ticket && (
        <table className='customTable'>
          <thead>
            <tr>
              <th style={{ width: 200 }}>Campo</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ID</td>
              <td>{ticket.assistance_number}</td>
            </tr>
            <tr>
              <td>Fecha / Hora Registro de solicitud</td>
              <td>{ticket.created_at}</td>
            </tr>
            <tr>
              <td>Fecha / Hora Programada</td>
              <td>{parseDate(ticket.meeting_datetime)}</td>
            </tr>
            <tr>
              <td>Tipo de requerimiento</td>
              <td>{ticket.requirement_type}</td>
            </tr>
            <tr>
              <td>Descripción</td>
              <td>{ticket.description}</td>
            </tr>
            <tr>
              <td>Lugar de encuentro</td>
              <td>{ticket.meeting_place}</td>
            </tr>
            <tr>
              <td>¿Con Aviso?</td>
              <td>{ticket.with_alert ? 'Sí' : 'NO'}</td>
            </tr>
          </tbody>
        </table>
      )}

      <h3 style={{ marginTop: 20 }}>Solicitante</h3>
      {ticket && (
        <table className='customTable'>
          <thead>
            <tr>
              <th style={{ width: 200 }}>Campo</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nombre Completo</td>
              <td>{ticket.applicant_full_name}</td>
            </tr>
            <tr>
              <td>Rut o Pasaporte</td>
              <td>{ticket.applicant_identification_number}</td>
            </tr>
            <tr>
              <td>Teléfono</td>
              <td>{ticket.applicant_phone_number}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{ticket.applicant_email}</td>
            </tr>
          </tbody>
        </table>
      )}
      <h3 style={{ marginTop: 20 }}>Pasajero</h3>
      {ticket && (
        <table className='customTable'>
          <thead>
            <tr>
              <th style={{ width: 200 }}>Campo</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nombre Completo</td>
              <td>{ticket.passenger_full_name}</td>
            </tr>

            <tr>
              <td>Rut o Pasaporte</td>
              <td>{ticket.passenger_identification_number}</td>
            </tr>
            <tr>
              <td>Teléfono</td>
              <td>{ticket.passenger_phone_number}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{ticket.passenger_email}</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className='attention'>
        <h1>Datos de Atención</h1>
        {!attentionEnabled && (
          <>
            <h2>Fuera de rango de hora programada</h2>
            <Button
              variant='contained'
              color='warning'
              onClick={() => setAttentionEnabled(true)}
            >
              Habilitar
            </Button>
          </>
        )}
        {ticket && (
          <Card sx={{ minWidth: 275 }} style={{ paddingBottom: 40 }}>
            <Formik
              initialValues={{
                responsible_scas: ticket.responsible_scas,
                canceled: ticket.status === 'Cancelado' ? true : false,
              }}
              onSubmit={(values, { resetForm }) => onSubmit(values)}
            >
              {({ handleChange, values }) => (
                <Form className='form' style={{ padding: 40 }}>
                  <div
                    className='col-md-12'
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <div className='form__element'>
                      <div className='form__title'>Anular</div>
                      <div className='form__radios materialRadios'>
                        <Checkbox name='canceled' disabled={!attentionEnabled}>
                          Anular
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className='cols'>
                    <div className='col-md-4 '>
                      <TxtInputMaterial
                        disabled={!attentionEnabled}
                        type='text'
                        name='responsible_scas'
                        label='Responsable SCAS'
                        placeholder='Nombre Apellido'
                      />
                    </div>
                    <div className='col-md-4'>
                      <TimePickerForm
                        disabled={!attentionEnabled}
                        txt={'Inicio Atención'}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    </div>
                  </div>
                  <div className='cols'>
                    <div className='col-md-12' style={{ marginTop: 20 }}>
                      <button type='submit' className='btn btn--small'>
                        Guardar{' '}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        )}
      </div>
    </div>
  );
}
