import { useField } from "formik";

const TextArea = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <div className="form__element">
      <div className="form__title">{label} </div>
      <div className="form__input">
        <textarea aria-label={label} type="text" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default TextArea;