import { useState, useCallback } from "react";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import Table from "../../Components/Table";
import createColumn from "../../utils/createColumn";
import { CreateColumn } from "../../interfaces/columns.interface";
import ModalUser from "./ModalUser";
import axios from "../../utils/axios";
import Swal from 'sweetalert2'

export default function Manage() {
  const [reload, setReload] = useState(0);
  const [userSelected, setUserSelected] = useState({});

  const deleteUser = (user: any) => () => {
    
    Swal.fire({
      title: '¿Deseas eliminar a este usuario?',
      text: "Esta acción no se puede deshacer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/users/${user.id}`).then((res) => {
          setReload(reload + 1);
        });
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })


  };

  const columns: CreateColumn = createColumn();
  columns.add("email", "Email");
  columns.add("first_name", "Nombre");
  columns.add("last_name", "Apellido");
  columns.add("role", "Rol");
  columns.addGetActions((params: any) => [
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Editar"
      onClick={() => setUserSelected({ ...params.row })}
    />,
    <GridActionsCellItem
      icon={<RemoveIcon />}
      label="Eliminar"
      onClick={deleteUser({ ...params.row })}
    />,
  ]);
  const executeReload = () => {
    setReload(reload + 1);
  };
  return (
    <div>
      <ModalUser user={userSelected} executeReload={executeReload} />
      <Table
        reload={reload}
        columns={columns.getColumn()}
        targetApi={"users"}
        modeOperand={"client"}
      />
    </div>
  );
}
