import TxtInput from "../Components/TxtInput";
import RadioButton from "../Components/RadioButton";
import CheckBox from "../Components/CheckBox";
import { useFormikContext } from 'formik';
import PhoneNumber from "../Components/PhoneNumber";
import Select from "../Components/Select";
import CountriesJson from './countries.json'


export default function PassengerForm({ label, ...props }: any) {
  const { values, setFieldValue, handleChange }: any = useFormikContext();
  const setPhoneNumber = (name: any, value: any) => {
    setFieldValue(name, value)
  }
  return (
    <>
      <div className="section__content">
        <h4 className="section__title">
          Información del Pasajero/ Passenger Information
        </h4>
        <div className="cols">
          <div className="col-md-6" style={{zIndex:0}}>
            <TxtInput
              type="text"
              name="passenger_name"
              label="Nombre / Name"
            />
          </div>
          <div className="col-md-6">
            <TxtInput
              type="text"
              name="passenger_lastname"
              label="Apellido / LastName"
            />
          </div>
          <div className="col-md-6" style={{ marginBottom: 0 }}>
            <div className="form__element">
              <div className="form__title">
                Rut o Pasaporte / DNI or Passport
              </div>
              <div className="form__radios">
                <RadioButton
                  name="passenger_DNI"
                  value="Rut"
                  label="Rut"
                />
                <RadioButton
                  name="passenger_DNI"
                  value="passport"
                  label="Pasaporte / Passport"
                />
              </div>
            </div>
          </div>
          {values?.passenger_DNI == "Rut" && (
            <div className="col-md-6">
              <TxtInput
                type="text"
                name="passenger_rut"
                label="Rut / N° ID"
              />
            </div>
          )}
          {values?.passenger_DNI == "passport" && (
            <div className="col-md-6">
              {/*            <Select
                  type="text"
                  name="passenger_country"
                  label="País / Country"
                >
                  {CountriesJson.map(x => <option value={x.code}>{x.name}</option>)}
                </Select> */}
              <TxtInput
                type="text"
                name="passenger_passport"
                label="Pasaporte / passport"
              />
            </div>
          )}
          <div className="col-md-6">
            <PhoneNumber
              name="passenger_phone_number"
              label="Teléfono / Phone"
              setPhoneNumber={setPhoneNumber}
            />
          </div>

          <div className="col-md-6">
            <TxtInput
              type="text"
              name="passenger_email"
              label="Correo Electrónico / Email"
            />
          </div>
        </div>
      </div>
    </>
  )
}
