import { useField } from "formik";

const Radio = ({ label, ...props }: any) => {
  const [field,meta] = useField({ ...props, type: "radio" });
  
  return (
    <div className="form__radio">
      <label  aria-label={label} style={{fontSize:13}}>
        <input type="radio" {...field} {...props} />
        {label}   {meta.touched && meta.error ? (
          <label className="error">{meta.error}</label>
        ) : null}
      </label>
    </div>
  );
};

export default Radio;
