import { useState, useCallback, useEffect } from "react";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import Table from "../../Components/Table";
import createColumn from "../../utils/createColumn";
import { CreateColumn } from "../../interfaces/columns.interface";
import { useHistory, Redirect } from "react-router-dom";
import DownloadExcel from "./DownloadExcel";
import StateTickets from "./StateTickets";
import Calendar from "./Calendar";
import axios from '../../utils/axios'
import { getGridSingleSelectOperators } from "@mui/x-data-grid";
export default function Panel() {
  const history = useHistory();

  const [assistanceCalendar, setAssistanceCalendar] = useState([])

  useEffect(() => {
    axios.get('assistances', { params: { query: { status_in: ["pending", "in_process"] } } }).then((res: any) => {
      res.data.assistances = res.data.assistances.map((item: any
      ) => {
        let date = item.meeting_datetime
        return {
          title: item.passenger_full_name,
          start: date,
          ide: item.id,
          status: item.status
        }
      }).filter((x: any) => x.status !== 'Cancelado')
      setAssistanceCalendar(res.data.assistances)
    });
  }, [])

  const editUser = useCallback(
    (id: GridRowId) => () => {
      history.push("/panel/" + id);
    },
    []
  );

  const columns: CreateColumn = createColumn();
  columns.add("assistance_number", "Número Seguimiento");
  columns.add("requirement_type", "Tipo Servicio");
  columns.add("passenger_full_name", "Nombre Pasajero");
  columns.add("with_alert", "Con aviso");
  columns.add("meeting_datetime", "Fecha Programada");
  columns.add("status", "Estado Solicitud", {
    filterOperators: getGridSingleSelectOperators(),
    valueOptions: [{ value: 0, label: 'Por procesar' },
    { value: 5, label: 'Completado' },
    { value: 4, label: 'Anulado' }],
    type: "singleSelect"
  });

  columns.addGetActions((params: any) => [
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Editar"
      onClick={editUser(params.row.id)}
    />,
  ]);

  return (
    <div>
      <StateTickets />
      <Table columns={columns.getColumn()} targetApi={"assistances"} modeOperand={'server'} />
      <DownloadExcel />
      {assistanceCalendar.length > 0 &&
        <Calendar myevents={assistanceCalendar} />
      }

    </div>
  );
}
