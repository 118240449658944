const get_id_passenger = (value:any)=>{
    const {same_person,passenger_rut,
      passenger_passport,rut,passport} = value
      if(same_person){
        let result = rut ? rut : passport
        return result 
      }else{
        let result = passenger_rut ? passenger_rut : passenger_passport
        return result 
      }
  }

  const get_name_passanger = (value:any)=>{
    const {name,lastname,
      passenger_name,passenger_lastname,
      same_person } =  value;
      if(same_person){
        return name + " " + lastname
      }else{
        return passenger_name + " " + passenger_lastname
      }
  }

const createFormData = (values: any) => {
    const {
        email, name, lastname, rut,
        passport, phone, description,
        passenger_email,
        passenger_phone_number,
        meeting_place,
        meeting_datetime,
        same_person,
        requirement_type
      } = values
      

      const formData = {
        "applicant_email": email,
        "applicant_full_name": name + " " + lastname,
        "applicant_identification_number": rut
          ? rut
          : passport,
        "applicant_phone_number": phone,
        "description": description,
        "passenger_identification_number": get_id_passenger(values),
        "passenger_phone_number":  same_person ?  phone : passenger_phone_number ,
        "passenger_full_name":  get_name_passanger(values),
        "passenger_email": same_person ? email : passenger_email,
        "meeting_place":meeting_place,
        "meeting_datetime": meeting_datetime,
        "requirement_type": requirement_type
      };
      return formData
}

const api =
      process.env.REACT_APP_ENV === "development"
        ? "http://localhost:3000/api/v1"
        : process.env.REACT_APP_API;


export {createFormData, api}