import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PmrForm from "./PublicViews/PmrForm";
import Login from "./Login/index";
import Dashboard from "./Dashboard/index";
import Panel from "./Dashboard/Panel";
import UpdateTIcket from "./Dashboard/Panel/UpdateTIcket";
import Manage from "./Dashboard/Manage";
import Report from "./Dashboard/Report";

function Routes() {
  const location = useLocation();
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path={["/panel", "/manage", "/report"]}>
        <Dashboard>
          <Switch location={location} key={location.pathname}>
            <Route path="/panel/:id" component={UpdateTIcket} />
            <Route path="/panel" component={Panel} />
            <Route path="/manage" component={Manage} />
            <Route path="/report" component={Report} />
          </Switch>
        </Dashboard>
      </Route>
      <Route path="/" component={PmrForm} />
    </Switch>
  );
}

export default Routes;
