import { useState } from "react";
import Box from "@mui/material/Box";
import FormLabel from '@mui/material/FormLabel';
import FormControl from "@mui/material/FormControl";
import DateForm from "../../../Components/DateForm";
import GraphPmr from "./GraphPmr"
import iReport from '../../../interfaces/report.interface';
import axios from "../../../utils/axios"
import useMsgFetch from '../../../hooks/useMsgFetch'


export default function PMRReport() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [report, setReport ]= useState<iReport | null>(null);
  
  const statusApi = useMsgFetch( (query:string) => 
  axios.get(query)
  .then((response:any) => response) );



  const onSubmit = async () => {
    if(!startDate || !endDate ){
      alert('Completar campo fecha')
      return false
    }

    let dataStartDate ='';
    let dataEndDate = '';
    if(startDate?.getMonth() && endDate?.getMonth()){
        dataStartDate =  `start_date=${startDate?.getFullYear()}/${startDate?.getMonth() + 1}/${startDate?.getDate()}&`
        dataEndDate =  `end_date=${endDate?.getFullYear()}/${endDate?.getMonth() + 1}/${endDate?.getDate()}`
    }
    const query = `/assistances/report?`+
    dataStartDate+
    dataEndDate
    
    const result = await statusApi.run(query);

    if( Object.keys(result.assistances).length === 0){
     alert('No hay registros en estas fechas')
     setReport(null)
   }else{
     setReport(result)
   }

  }

  return (
    <div style={{ marginTop:50}}>
      <Box sx={{ minWidth: 120 }}>
       
       <h1>Reporte Total PMR</h1>

       <div style={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
        <FormControl >
          <FormLabel id="demo-controlled-radio-buttons-group">Periodo de análisis</FormLabel>
          <div style={{ marginTop:20, marginBottom:20 , display: 'flex'}}>
            <DateForm txt={'Fecha Inicio'} startDate={startDate} setStartDate={setStartDate} />
            <DateForm txt={'Fecha Final'} startDate={endDate} setStartDate={setEndDate} />
          </div>
        </FormControl>
        </div>
        <br></br>
        <button  className="btn btn--small noprint"  onClick={()=>onSubmit()}> {statusApi.loading ? 'Cargando': 'Buscar '}</button>

      </Box>
            {report && <GraphPmr report={report}  title={['CRS','Tickets']}/>  }
    </div>
  );
}