import { useField } from "formik";

const TxtInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <div className="form__element">
      <div className="form__title">
          <label htmlFor={field.name }> {label}</label>      
              {meta.touched && meta.error ? (
          <label htmlFor={field.name } className="error"> {meta.error}</label>
        ) : null} </div>
      <div className="form__input">
        <input aria-label={label} type="text" {...field} {...props} />
      
      </div>
    </div>
  );
};

export default TxtInput;
