export const parseDate = (datetime: string) => {
    let date = new Date(datetime)
    const localDate = date.toLocaleDateString().split("-").join("/");
    let localTime = date.toLocaleTimeString().split(":");
    let formatMeeting = `
    ${localDate}
    
    ${localTime[0]}:${localTime[1]}
    `
    return formatMeeting
}